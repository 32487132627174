import { Fragment, useEffect } from "react";
import Layout from "../../../layouts/Layout";
import { useLocation, useNavigate } from "react-router-dom";
const successImg = "/assets/demos/success.png";
const failedImage = "/assets/demos/failure.png";

const Result = () => {   

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  const onGotoWelcome = () => {
    navigate(process.env.PUBLIC_URL + "/shop/welcome");
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="pt-90 pb-100 shop-cart-main-area">
          {state?.result?<div className="container justify-content-center align-items-center  result-modal">
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <img src={
                process.env.PUBLIC_URL +
                successImg
              } width={70}></img>
            </div>
            <div className="row justify-content-center align-items-center pt-30" style={{textAlign: "center"}}>
              <h4 style={{fontSize:"22px", lineHeight:"33px"}}>Your order is being processed</h4>
            </div>
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <button className="btn-in-shop" onClick={onGotoWelcome} title="SEARCH ANOTHER PRODUCT">SEARCH ANOTHER PRODUCT</button>
            </div>    
          </div>:
          <div className="container justify-content-center align-items-center  result-modal">
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <img src={
                process.env.PUBLIC_URL +
                failedImage
              } width={70}></img>
            </div>
            <div className="row justify-content-center align-items-center pt-30" style={{textAlign: "center"}}>
              <h4 style={{fontSize:"22px", lineHeight:"33px"}}>You are not eligible to buy this product</h4>
            </div>
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
              <button className="btn-in-shop" onClick={onGotoWelcome}  title="SEARCH ANOTHER PRODUCT">SEARCH ANOTHER PRODUCT</button>
            </div>    
          </div>}
        </div>
      </Layout>
    </Fragment>
  );
};

export default Result;
