
const Welcome = () => {

  return (  
    <div className="bottom-logo-line">
      <img className="left-bottom-logo" alt="" src={'/assets/img/home/bottomLogo.png'} /> 
      <img className="right-bottom-logo" alt="" src={'/assets/img/home/shareRing_2023.png'} />
    </div>     
  )
}

export default Welcome;
