import { Fragment } from "react";
const DeviceCheckFailed = ({result = false}) => {     
  return (
    <Fragment>      
      <div className="row" style={{position:"absolute", height: "100%", width: "100%", background: "red"}}>
      </div>
      <div className="justify-content-center align-items-center" style={{ textAlign: "center", left: "50%", position: "absolute", top: "50%",  transform: "translate(-50%, -50%)", padding: "10px"}}>
          <p style={{color: "white", fontSize: "15px"}}>Sorry, You can only claim this once!</p>
      </div>    
    </Fragment>
  );
};

export default DeviceCheckFailed;
