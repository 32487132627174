import PropTypes from "prop-types";
import { Modal, Form, Button } from "react-bootstrap";
import {useState} from 'react';

function SubmitModal({show, onSubmit}) {  
  const [cronosAddress, setCronosAddress] = useState("");

  const onCloseModal = () => {
    //onHide()
  }

  const onSave = () => {    
    onSubmit(cronosAddress);
  }

  const onChange = (e) => {
    let fleldVal = e.target.value;
    setCronosAddress(fleldVal);
    console.log('fieldVal', fleldVal);
  }

  return (
    <Modal show={show} onHide={onCloseModal} className="verifyModal">    
      <div className="modal-body">
        <div className="row">
          <h4 style={{textAlign: "center"}}>{`Please input your cronos address!`}</h4>
        </div>
        <div className="row justify-content-center align-items-center" style={{ textAlign: "center", background: "white", padding: "20px" }}>
            <Form>
              <Form.Group className="mb-3" controlId="cronos">                
                <Form.Control type="text" placeholder="" onInput={onChange} value={cronosAddress}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Button variant="primary" onClick={onSave}>Submit</Button>
              </Form.Group>
            </Form>    
        </div>
     </div>
    </Modal>
  );
}

SubmitModal.propTypes = {
  onSubmit: PropTypes.func,
  show: PropTypes.bool,  
};

export default SubmitModal;
