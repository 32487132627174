import { Fragment,  } from "react";
import Box from '@mui/material/Box';
import TopSection from "../../../components/home/TopSection"
import MiddleSection from "../../../components/home/MiddleSection"
import BottomSection from "../../../components/home/BottomSection"
import Bottom from "../../../components/home/Bottom"
import ScrollToTop from "../../../components/scroll-to-top"

const contactUrl = 'https://sharering.network/contact'
const googleUrl = 'https://play.google.com/store/apps/details?id=network.sharering.me'
const appleUrl = 'https://apps.apple.com/app/sharering-me/id6476899324'

const demos = [
  {img:'/assets/img/home/venueCheck-In.png', url:'/building/check', title:'Venue Check-In',description:'Enhance guest-management and check-in experiences.'},
  {img:'/assets/img/home/hotelCheck-In.png', url:'/hotel/welcome', title:'Hotel Check-In',description:'Automate your check-in processes and reduce waiting time to less than a minute.'},
  {img:'/assets/img/home/cryptoExchange.png', url:'/crypto-exchange/welcome', title:'Crypto Exchange',description:'Enhance KYC workflows with fraud prevention and reduce your verification processing time.'},
  {img:'/assets/img/home/bankApplication.png', url:'/bank/welcome', title:'Bank Application',description:'Users can reuse their identity information to save them re-entering the same information multiple times across your business.'},
  {img:'/assets/img/home/visaApplication.png', url:'/visa/welcome', title:'Visa Application',description:'Automate the process of filling out applications with our cutting edge technology and drastically reduce processing time for your business.'},
  {img:'/assets/img/home/nightClubAgeVerification.png', url:'/nightclub/welcome', title:'Night Club Age Verification',description:'Eliminate manual verifications and enhance entrance experience for your patrons.'},
  {img:'/assets/img/home/purchaseAlcohol.png', url:'/shop/welcome', title:'Purchase Alcohol',description:'Verify that you are over 18 to purchase alcohol.'},
  {img:'/assets/img/home/deliveryAlcohol.png', url:'/delivery18', title:'Delivery Alcohol',description:'Verify that you are over 18 to receive alcohol.'},
  {img:'/assets/img/home/verifyDevice.png', url:'/device/check', title:'Verify device',description:' Scan QR code to share device information'},
  {img:'/assets/img/home/voting.png', url:'/vote/welcome', title:'Voting',description:'Please cast your vote by scanning the QR CODE and respond in the app.'},
  {img:'/assets/img/home/genericAgeVerification.png', url:'/age', title:'Generic Age Verification',description:' Scan QR code to verify age'},
]

const Home = ({ }) => {

  const handleClick = (e,item) => {
    window.open(process.env.PUBLIC_URL + item.url, "_blank");    
  };

  const handleExplore = () => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  }

  const handleContact = () => {   
    window.open(process.env.PUBLIC_URL + contactUrl, "_blank");
  };  
  
  const onClickApple = () => {   
    window.open(process.env.PUBLIC_URL + appleUrl, "_blank");
  };  
  
  const onClickGoogle = () => {   
    window.open(process.env.PUBLIC_URL + googleUrl, "_blank");
  };

  return (
    <Fragment>
      <Box className="home-area">
        <TopSection
          handleExplore={handleExplore}
          handleContact={handleContact}
        />
        <MiddleSection
          onClickApple={onClickApple}
          onClickGoogle={onClickGoogle}
        />
        <BottomSection
          onClickDemo={handleClick}
          demos = {demos}
         />
        <Bottom/>
        <ScrollToTop/>
      </Box>
    </Fragment>
  )
}
export default Home;
