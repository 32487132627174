import { Fragment, useEffect, useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

const BuildingCheckSuccess = ({ name = "", email = "", phone = "" }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());  
  const [seconds, setSeconds] = useState(5);
  const location = useLocation();
  const data = location.state;
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDateTime = currentDateTime.toLocaleDateString('en-US', options);
  const time = formattedDateTime.split('at')[1];
  const week = formattedDateTime.split('at')[0].split(",")[0];
  const date = formattedDateTime.split('at')[0].split(",")[1];
  const navigate = useNavigate();  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // Update every mintue

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Decrease the seconds every 1 second
    const intervalCounter = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);      
    }, 1000);

    // Clean up the interval and stop when seconds reach 0
    return () => {
      clearInterval(intervalCounter);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {      
      navigate('/building/check');
      window.location.reload();
    }
  }, [seconds, navigate]);

  return (
    <div style={{ height: '100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url("/assets/img/BG.png")` }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', color: "white", fontFamily: "Avenir", paddingTop: "30px" }}>
        <div style={{ width: "220px", textAlign: "right", padding: "10px" }}>
          <div style={{ borderRight: "2px solid rgba(255, 255, 255, 0.2)", paddingRight: "20px" }}>
            <span style={{ fontSize: "16px" }}>{date}</span><br />
            <span style={{ fontSize: "24px" }}>{week}</span>
          </div>
        </div>
        <div style={{ width: "220px", textAlign: "right", padding: "20px", paddingTop: "30px", paddingLeft: "0px", fontSize: "48px" }}>
          <span>{time}</span>
        </div>
      </div>
      <div className="justify-content-center align-items-center" style={{ width: "100%", textAlign: "center", left: "50%", position: "absolute", top: "50%", transform: "translate(-50%, -50%)", padding: "10px" }}>
        <div className="row">
          <span style={{ textAlign: "center", fontSize: "80px", color: "white", fontFamily: "Big Caslon" }}>{`Thank you`}</span>
        </div>
        <div className="row" style={{marginTop: "60px"}}>
          <span style={{ textAlign: "center", fontSize: "80px", color: "white", fontFamily: "Big Caslon" }}>{`${data?.name}`}!</span>
        </div>
        <div className="justify-content-center align-items-center pt-20" style={{textAlign: "center", paddingTop: "100px"}}>
            <button className="btn-purchase" style={{padding: "10px", width: "200px"}}>
              <Link reloadDocument to={process.env.PUBLIC_URL + "/building/check"} style={{color: "white", padding: "10px"}}>
                    Back to home screen
              </Link>
            </button>
        </div>
        <div className="row" style={{paddingTop: "30px"}}>
            <h3 style={{textAlign: "center", fontSize: "14px", color: "#5998D4", fontFamily: "Avenir"}}>{`Auto backing to home screen after ${seconds} seconds!`}</h3>
        </div>      
      </div>
      <div className="justify-content-center align-items-center" style={{ left: "50%", position: "absolute",bottom:'0px', transform: "translate(-50%, -50%)"}}>
        <div className="row" style={{textAlign:"center", color: "white",  marginBottom: "10px"}}>
          <img src="/assets/img/logo.png" style={{width: "50px", margin: "auto"}}/>            
        </div>      
        <div className="row" style={{textAlign:"center", color: "white"}}>
          <span>Powered By ShareRing</span>  
        </div>            
      </div> 
    </div>
  );
};

export default BuildingCheckSuccess;
