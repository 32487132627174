const TEST_MODE = 0;
const CURRENT_MODE = 2;

class Config {
  constructor() {
    this.config = {};
    this.configReady = false;
    this.BACKEND_API_URL = CURRENT_MODE == TEST_MODE ? "https://api.testenv.sharetoken.io" : "https://api.shareri.ng";
    this.AUTH_API_URL = CURRENT_MODE == TEST_MODE ? "https://api.testenv.sharetoken.io/auth" : "https://api.shareri.ng/auth";
  }
}

export default new Config();

export const ENV = TEST_MODE;
export const SECURITY_WORD = "123456";
export const SECURITY_WORD_ENCRYTED =
"U2FsdGVkX19iqp50jwTOXkZMli4w8rtTjDIN1/jNqxU=";

// export const HOST = CURRENT_MODE == TEST_MODE ? "https://api.testenv.sharetoken.io" : "https://api.shareri.ng";
// export const DEEP_LINK_PREFIX = CURRENT_MODE == TEST_MODE ? "https://sharering.page.link/?link=https://sharering.network/vql?" : `https://sharering.page.link/?link=https://sharering.network/vql?`;
// export const DEEP_LINK_SUFFIX = CURRENT_MODE == TEST_MODE ? "&apn=network.sharering.travel" : "&apn=network.sharering.application";

export const IDENTIFI_DEEP_LINK_PREFIX = TEST_MODE ? "https://shareringmetest.page.link/?link=https://sharering.network/vql?" : "https://shareringme.page.link/?link=https://sharering.network/vql?";
export const IDENTIFI_DEEP_LINK_SUFFIX = TEST_MODE ? "&apn=network.sharering.shareringmetest" : "&apn=network.sharering.me&isi=6476899324&ibi=network.sharering.shareringme";

export const DEEP_LINK_PREFIX = TEST_MODE ? "https://shareringmetest.page.link/?link=https://sharering.network/vql?" : "https://shareringme.page.link/?link=https://sharering.network/vql?";
export const DEEP_LINK_SUFFIX = TEST_MODE ? "&apn=network.sharering.shareringmetest" : "&apn=network.sharering.me&isi=6476899324&ibi=network.sharering.shareringme";
export const HOST = "https://api-testnet.shareri.ng";

/* 
# test: &apn=network.sharering.vault and prod: &apn=network.sharering.identifime&isi=6448408441&ibi=network.sharering.identifime
REACT_APP_DEEPLINK_SUFFIX=&apn=network.sharering.identifime&isi=6448408441&ibi=network.sharering.identifime

# test: https://api.testenv.sharetoken.io and prod: https://api.shareri.ng
REACT_APP_BACKEND_API_URL=https://api.shareri.ng

# test: https://api.testenv.sharetoken.io/auth and prod: https://api.shareri.ng/auth
REACT_APP_AUTH_API_URL=https://api.shareri.ng/auth


const prefix = process.env.REACT_APP_DEEPLINK_PREFIX || "https://identifime.page.link";
export const DEEP_LINK_PREFIX = "https://identifime.page.link/?link=https://sharering.network/vql?;
export const DEEP_LINK_SUFFIX = "&apn=network.sharering.identifime&isi=6448408441&ibi=network.sharering.identifime";


*/