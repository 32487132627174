import {  useEffect } from "react";

const InputForm = ({label,inputValue,type, changeInput}) => {

  useEffect(() => {
  }, []);
  const onChangeInput = (e) => {
    changeInput(e) 
  };

  return ( 
    <div className="input-form">
        <div className="input-label"> {label} </div>
        <input
            className="input-text"
            onChange={(e) => {onChangeInput(e) }}
            value={inputValue}
            type={type}
        />
    </div>     
  )
}
export default InputForm;
