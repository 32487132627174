import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
const backgroundImg = "/assets/demos/confetti.png";
const successIcon = "/assets/demos/success.png";
const failureIcon = "/assets/demos/failure.png";
const VOTE_VALUE = [
  "YES", "NO", "Abstain"
]
function SuccessModal({show, onHide}) {

  const onCloseModal = () => {
    onHide()
  }

  return (
    <Modal show={show} onHide={onCloseModal} className="successModal">    
      <div className="modal-body">
        <div style={{width: "100%", height: "100px",   backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: 'url("' + process.env.PUBLIC_URL+backgroundImg + '"})'}}>
          <div className="justify-content-center align-items-center" style={{textAlign: "center"}}><img src={`${process.env.PUBLIC_URL}${successIcon}`} width={50}/></div>
          
          <h4 style={{textAlign: "center"}}>{`Request sent, please prepare cash to exchange.`}</h4>
        </div>
        {/* <div className="row">
          <span style={{color: "#333333", fontSize: "12px", textAlign:"center"}}>You are eligible to buy these products. We hope you enjoy the purchasing journey.</span>
        </div> */}
     </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  qrcode: PropTypes.string
};

export default SuccessModal;
