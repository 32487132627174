import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Layout from "../../../layouts/Layout";
import { addToShopCart, decreaseQuantity, deleteFromShopCart } from "../../../store/slices/shopCart-slice";
import ProductModal from "./VerifyModal";
import SuccessModal from "./SuccessModal";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV
} from "../../../services/graphql/config";
import VerifyModal from "./VerifyModal";

const INIT_CART_DATA = [
  {
    cartItemId: "25d8147d-2286-4c16-83ba-e63dd3681042",
    name: "Veuve Clicquot Vintage 2015 Gift Boxed",
    id: 1,
    price: 149.99,
    quantity: 1,
    fee:2,
    image: "/assets/img/cart/product1.png"
  },
  {
    cartItemId: "35d8147d-2286-4c16-83ba-e63dd3681042",
    name: "Moët & Chandon Brut Imperial NV Festive Gift Boxed",
    id: 2,
    price: 69.99,
    quantity: 1,
    fee:1,
    image: "/assets/img/cart/product2.png"
  }
]
const cryptoJS = require("crypto-js");

const AGE_QUERY_ID = ENV ? "654de78c12895900559e5a10" : "65533af10635c10060dfdbfa"; 
let sessionId = "";

const Welcome = () => {
  let cartTotalPrice = 0;
  let cartTotalFee = 0;
  let cartSubTotalPrice = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopCartItems } = useSelector((state) => state.shopCart);
  const [modalVerifyShow, setVerifyModalShow] = useState(false);
  const [modalSuccessShow, setSuccessModalShow] = useState(false);
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [quantityCount] = useState(1);
  const [initialized, SetInitalized] = useState(false);

  useEffect(() => {
    if (!initialized && shopCartItems.length == 0) {
      INIT_CART_DATA.map((cartItem) => {
        dispatch(addToShopCart({
          ...cartItem,
          quantity: quantityCount
        }))
      })
      SetInitalized(true);
    }
    console.log('shopCartItems:',shopCartItems)
  }, [shopCartItems])

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      if (response.status === 1) {
        let data =
          DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${AGE_QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {
    console.log('onGetQRRequests', status);
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: AGE_QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };
    APIServices.getQRRequestItems(params, (response) => {
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );
            console.log('data', data);
            let age = JSON.parse(data).qrRes;
            setVerifyModalShow(false);
            if (age == "Thank you, your age has been verified") {
              setStatus(1);
              setSuccessModalShow(true);
            } else {
              setStatus(-1);
              navigate(process.env.PUBLIC_URL + "/shop/result", { state: { result: false } });
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const goSuccessResult = () => {
    navigate(process.env.PUBLIC_URL + "/shop/result", { state: { result: true } });
    
  }

  const trigerMenu = () =>{
    setShowMenu(!showMenu)
  }

  const onVerify = () =>{
    setSuccessModalShow(false)
  }
  
  const goDeepLink = () => {
    console.log('goDeepLink');
    if (qrCode)
      window.open(qrCode, "_blank");
  }

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="shop-cart-main-area">
          <div className="header-backgrund">
            <div className="container">
              <div className="header">
                <img className="logo" alt="" src="/assets/img/cart/logo.png"/>
                <div className="header-content">
                  <div className="top-row">
                    <div>CONTACT US</div>
                    <div className="line"></div>
                    <div>GIFTING</div>
                    <div className="line"></div>
                    <div>THE DROP</div>
                  </div>
                  <div className="middle-row">
                    <div class="search-container">
                      <input 
                        type="text" 
                        id="search-input" 
                        class="search-input" 
                        style={{backgroundImage: "url(" + "/assets/img/cart/search.png" + ")" ,borderRadius:'8px'}}
                        placeholder="Search brand, type, variety or region"/>
                    </div>
                    <div className="cart">
                      <img alt="" width={20} src="/assets/img/cart/cart.png"/>
                      <div className="cart-text">Cart</div>
                    </div>
                  </div>
                  <div className="bottom-row">
                    <div>BEER</div>
                    <div className="line"></div>
                    <div>SPARKLING</div>
                    <div className="line"></div>
                    <div>WHITE</div>
                    <div className="line"></div>
                    <div>RED</div>
                    <div className="line"></div>
                    <div>SPIRITS</div>
                    <div className="line"></div>
                    <div>GIFTS</div>
                    <div className="line"></div>
                    <div>REFRESHMENTS</div>
                    <div className="line"></div>
                    <div>LOW / NO ALC</div>
                  </div>
                  <div className="bottom-menu">
                    <img onClick={trigerMenu} className="menu-icon" alt="" src="/assets/img/cart/menu.png"/>
                    {showMenu&& <div className="sub-menu">
                      <div>BEER</div>
                      <div className="line"></div>
                      <div>SPARKLING</div>
                      <div className="line"></div>
                      <div>WHITE</div>
                      <div className="line"></div>
                      <div>RED</div>
                      <div className="line"></div>
                      <div>SPIRITS</div>
                      <div className="line"></div>
                      <div>GIFTS</div>
                      <div className="line"></div>
                      <div>REFRESHMENTS</div>
                      <div className="line"></div>
                      <div>LOW / NO ALC</div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="container">
            {shopCartItems && shopCartItems.length >= 1 ? (
              <Fragment>
                <h3 className="cart-page-title">Shopping Cart</h3>
                <div className="to-product-list"> {'< Back to product list'}</div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="table-content table-responsive shop-cart-table-content">
                      <table>
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th className="empty-th"></th>
                            <th className="product-price">Price</th>
                            <th className="product-quantity">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shopCartItems.map((cartItem, key) => {
                            cartSubTotalPrice = cartSubTotalPrice + cartItem.price * cartItem.quantity
                            cartTotalFee = cartTotalFee + cartItem.fee*cartItem.quantity
                            cartTotalPrice = cartSubTotalPrice + cartTotalFee
                            return (
                              <tr key={key}>
                                <td className="product-thumbnail">
                                  <img
                                    className="img-fluid"
                                    src={
                                      process.env.PUBLIC_URL +
                                      cartItem.image
                                    }
                                    alt=""
                                  />
                                </td>
                                <td className="product-name">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    {cartItem.name}
                                  </Link>
                                </td>
                                <td className="product-subtotal">
                                  {"$" +
                                    (
                                      cartItem.price * cartItem.quantity
                                    ).toFixed(2)}
                                </td>
                                <td className="product-quantity">
                                  <div className="cart-plus-minus">
                                    <button
                                      className="dec qtybutton"
                                      onClick={() =>
                                        dispatch(decreaseQuantity(cartItem))
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="cart-plus-minus-box"
                                      type="text"
                                      value={cartItem.quantity}
                                      readOnly
                                    />
                                    <button
                                      className="inc qtybutton"
                                      onClick={() =>
                                        dispatch(addToShopCart({
                                          ...cartItem,
                                          quantity: quantityCount
                                        }))
                                      }
                                      disabled={
                                        cartItem !== undefined &&
                                        cartItem.quantity == 0
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                  <div className="product-remove">
                                    <button
                                      onClick={() =>
                                        dispatch(deleteFromShopCart(cartItem.cartItemId))
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="result-total">
                      <div className="selector-group">
                        <div className="selector-wrap">
                          <h4 className="label">
                            Coupon
                          </h4>
                          <h4 className="select">
                            Select
                            <span className="arrow">
                              {">"}
                            </span>
                          </h4>
                        </div>
                        <div className="selector-wrap">
                          <h4 className="label">
                            Payment Method
                          </h4>
                          <h4 className="select">
                            Select
                            <span className="arrow">
                              {">"}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className="price-group">
                        <h5 className="price-text-line">
                          Subtotal{" "}
                          <span>
                            {"$" + cartSubTotalPrice.toFixed(2)}
                          </span>
                        </h5>
                        <h5 className="price-text-line">
                          Discount{" "}
                          <span>
                            {0}
                          </span>
                        </h5>
                        <h5 className="price-text-line">
                          Shipping fee{" "}
                          <span>
                            {"$" + cartTotalFee.toFixed(2)}
                          </span>
                        </h5>
                      </div>
                      <h4 className="total-val-title">
                        Total{" "}
                        <span className="total-val">
                          {"$" + cartTotalPrice.toFixed(2)}
                        </span>
                      </h4>
                      <div className="justify-center">
                        {/* {checked && status === 0 && <button className="btn-in-shop" onClick={() => goDeepLink()} title="Complete checkout">COMPLETE CHECKOUT</button>}
                        {!checked && status === 0 && <button className="btn-in-shop" onClick={() => setVerifyModalShow(true)} title="Verify Age">VERIFY AGE</button>}
                        {status !== 0 && <Link className="btn-in-shop" reloadDocument params={{ result1: true }} to={process.env.PUBLIC_URL + "/purchase/result/18/success"} style={{ color: "white", padding: "10px" }}>
                          COMPLETE CHECKOUT
                        </Link>} */}
                        {status === 1 ? <button className="btn-in-shop" onClick={() => goSuccessResult()} title="Complete checkout">COMPLETE CHECKOUT</button>:
                         <button className="btn-in-shop" onClick={() => setVerifyModalShow(true)} title="Verify Age">VERIFY AGE</button>}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-cart"></i>
                    </div>
                    <div className="item-empty-area__text">
                      No items found in cart <br />{" "}
                      <Link reloadDocument to={process.env.PUBLIC_URL + "/shop/welcome"}>
                        Refresh
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
      <VerifyModal
        show={modalVerifyShow}
        onHide={() => setVerifyModalShow(false)}
        qrcode={qrCode}
        age={18}
        goDeepLink={goDeepLink}
      />
      <SuccessModal
        show={modalSuccessShow}
        onHide={() => setSuccessModalShow(false)}
        onVerify={() => onVerify()}
        
        qrcode={qrCode}
        age={18}
      />
    </Fragment>
  );
};

export default Welcome;
