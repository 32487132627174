import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const shopCartSlice = createSlice({
    name: "cart",
    initialState: {
        shopCartItems: [
        ]
    },
    reducers: {
        addToShopCart(state, action) {
            const product = action.payload;
            const cartItem = state.shopCartItems.find(item => item.id === product.id);
            if(!cartItem){
                state.shopCartItems.push({
                    ...product,
                    quantity: product.quantity ? product.quantity : 1,
                    cartItemId: uuidv4()
                });
            } else {
                state.shopCartItems = state.shopCartItems.map(item => {
                    if(item.cartItemId === cartItem.cartItemId){
                        return {
                            ...item,
                            quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
                        }
                    }
                    return item;
                })
            }
            cogoToast.success("Added To Cart", {position: "bottom-left"});
        },
        deleteFromShopCart(state, action) {
            state.shopCartItems = state.shopCartItems.filter(item => item.cartItemId !== action.payload);
            cogoToast.error("Removed From Cart", {position: "bottom-left"});
        },
        decreaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.shopCartItems = state.shopCartItems.filter(item => item.cartItemId !== product.cartItemId);
                cogoToast.error("Removed From Cart", {position: "bottom-left"});
            } else {
                state.shopCartItems = state.shopCartItems.map(item =>
                    item.cartItemId === product.cartItemId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
                cogoToast.warn("Item Removed From Cart", {position: "bottom-left"});
            }
        },
        deleteAllFromCart(state){
            state.shopCartItems = []
        }
    },
});

export const { addToShopCart, deleteFromShopCart, decreaseQuantity, deleteAllFromShopCart } = shopCartSlice.actions;
export default shopCartSlice.reducer;
