import Grid from '@mui/material/Grid';

const TopSection = ({handleExplore, handleContact}) => {

  return (     
      <div className="top-section"
        style={{ backgroundImage: "url('/assets/img/home/topBackground.png')"}}>
        <div className="top-logo">
          <img className="" style={{height:'48px'}} alt="" src="/assets/img/home/logo.png" />
        </div>
        <Grid className="top-content" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
          <Grid item xs={12} sm={6} md={6}>
            <div className="title">
              Discover the most suitable demo for your business
            </div>
            <div className="description">
              Learn how our solutions can help you improve cost efficiencies, saving your business valuable time and money. Download our app to experience interacting with our product as your customer would.
            </div>
            <div top>
              <button className="top-fill-btn" onClick={handleExplore}>
                Explore our demos
              </button>
            </div>
            <div>
              <button className="top-outline-btn" onClick={handleContact}>
                Speak to our representative
              </button>        
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <img className="top-img" alt="" src="/assets/img/home/demoGroup.png" />
          </Grid>
        </Grid> 
      </div>
      
  )
}
export default TopSection;
