import gql from "graphql-tag";
import GRAPHQL from "./index";

export default class {
  static getQueryItems(params, callback) {
    const query = {
      query: gql`
        query {
          getQueryItems(
            userWalletAddress:"${params.userWalletAddress}",
            page:${params.page},
            pageSize:${params.pageSize}),
            {
              data {
                items {
                  _id
                  name
                  title
                  apiEndpointId
                  schemePrefix
                  _created
                  color
                  user_wallet_address
                  query
                  required_fields
                  apiEndpoint {
                    url
                    title
                  }
                }
                page
                pageSize
                total
              }
            }
       }`,
    };

    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response?.data?.getQueryItems?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static createQueryItem(params, callback) {
    const strQuery = JSON.stringify(params.query);
    const query = {
      mutation: gql`
        mutation {
          postQuery (
            name:"${params.name}",
            title:"${params.title}",
            strQuery:${strQuery},
            userWalletAddress:"${params.userWalletAddress}",
            color:"${params.color}",
            createdBy: "${params.createdBy}",
            apiEndpointId: "${params.apiEndpointId || ""}",
            schemePrefix:"${params.schemePrefix || ""}",
            required_fields:"${params.required_fields}")        
          {
            data {
              _id
              title
            }
            status
            success
            message     
          }
        }   
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          if (response.data.postQuery.success === "false") {
            throw Error(response.data.postQuery.message);
          }
          return callback({
            status: 1,
            data: response.data.postQuery.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static updateQueryItem(params, callback) {
    const strQuery = JSON.stringify(params.query);
    const query = {
      mutation: gql`
        mutation {
          updateQueryDetail (
            _ID: "${params._id}", 
            queryInfo: { 
              name:"${params.name}",
              title:"${params.title}",
              query: ${strQuery},
              color:"${params.color}",
              userWalletAddress:"${params.userWalletAddress}",
              apiEndpointId:"${params.apiEndpointId || ""}",
              schemePrefix:"${params.schemePrefix}"
              required_fields:"${params.required_fields}"
            }) 
          {
            status
            message
            success
            data {
                _id
                name
                title
                query
                color
                apiEndpointId
                user_wallet_address
                schemePrefix
            }      
          }
        }
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          if (response.data.updateQueryDetail.success === "false") {
            throw Error(response.data.updateQueryDetail.message);
          }
          return callback({
            status: 1,
            data: response.data.updateQueryDetail.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static generateQRSession(callback) {
    const query = {
      mutation: gql`
        mutation {
          generateQRSession {
            status
            message
            data {
              session_id
            }
          }
        }
      `,
    };
    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response.data.generateQRSession.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.Message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static deleteQueryItem(params, callback) {
    const query = {
      mutation: gql`
        mutation {
          deleteQuery(_ID: "${params._id}") {
            status
            message
            data {
                _id
                name
                query
            }      
          }
        }
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response.data.deleteQuery.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.Message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static getQRRequestItems(params, callback) {
    const query = {
      query: gql`
        {
          getQRRequestItems (
            queryId: "${params.queryId}"
            sessionId: "${params.sessionId}"
            userName: "${params.userName}"
            type: "query"
            page:${params.page}
            pageSize:${params.pageSize}
            )
            {
              status
              message
              data {
                items {_id
                  query_id
                  session_id
                  result
                  completion
                  _created
                  _updated
                  user_name
                  qr_owner
                  query_info {                     
                      name
                      title
                      color
                      user_wallet_address
                      created_by
                      _created
                      _updated
                  }
                }
                page
                pageSize
                total
              }
            }
        }`,
    };
    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response?.data?.getQRRequestItems?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static getQRRequestItemsForLogin(params, callback) {
    const query = {
      query: gql`
        {
          getQRRequestItems (
            queryId: "${params?.queryId}"
            sessionId: "${params?.sessionId}"
            userName: "${params?.userName}"
            type: "login" 
            userWalletAddress: ""
            page:${params?.page}
            pageSize:${params?.pageSize}
            )
            {
              status
              message
              data {
                items {_id
                  query_id
                  session_id
                  result
                  user_wallet_address
                  user_name
                  type
                  query_info {
                    _id
                    name
                    title
                  }
                  completion
                  _created
                  _updated
                }
                page
                pageSize
                total
              }
            }
        }`,
    };

    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response?.data?.getQRRequestItems?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static deleteQRRequestItem(params, callback) {
    const query = {
      mutation: gql`
        mutation {
          deleteQRRequest(sessionId: "${params.sessionId}") {
            status
            message
            data {
              session_id
            }      
          }
        }
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response?.data?.deleteQRRequest?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.Message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static getQRSecurityItem(user_wallet_address, callback) {
    const query = {
      query: gql`
        {
          getQRSecurityItem(
            user_wallet_address: "${user_wallet_address}") 
            {
              status
              message
              success
              data {
                _id
                user_wallet_address
              }
            }
        }`,
    };

    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            success: response?.data?.getQRSecurityItem?.success,
            data: response?.data?.getQRSecurityItem?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static registerQRSecurity(params, callback) {
    const query = {
      mutation: gql`
        mutation{
          createQRSecurity(
            user_wallet_address:"${params.userWalletAddress}",
            security_word:"${params.security_word}")        
          {
            status
            message
            success
            data {
                _id
                user_wallet_address
            }      
          }
        }   
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            success: response?.data?.createQRSecurity?.success,
            data: response?.data?.createQRSecurity?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.Message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static validateQRSecurity(params, callback) {
    const query = {
      mutation: gql`
        mutation{
          validateQRSecurity(
            user_wallet_address:"${params.userWalletAddress}",
            security_word:"${params.security_word}")        
          {
            status
            message
            success
            data {
                _id
                user_wallet_address
            }      
          }
        }   
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            message: response?.data?.validateQRSecurity?.message,
            success: response?.data?.validateQRSecurity?.success,
            data: response?.data?.validateQRSecurity?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.Message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }
  static getQRSecurityEncrypt(word, callback) {
    const query = {
      query: gql`
        {
          getQRSecurityEncrypt(
            word: "${word}") 
            {
              status
              message
              success
              data {
                encrypted
              }
            }
        }`,
    };

    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            success: response?.data?.getQRSecurityEncrypt?.success,
            data: response?.data?.getQRSecurityEncrypt?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static getQueryById(id, callback) {
    const query = {
      query: gql`
        query {
          getQueryItem(id: "${id}")
          {
            status
            message
            data {
              _id
              name
              title
              query
              color
              schemePrefix
            }
          }
        }`,
    };

    GRAPHQL.doQuery(query)
      .then((response) => {
        try {
          return callback({
            status: 1,
            data: response?.data?.getQueryItem?.data,
          });
        } catch (e) {
          return callback({ status: 0, message: "HTTPERROR" });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }

  static registerQueryShortLink(params, callback) {
    const strQuery = JSON.stringify(params.query);
    const query = {
      mutation: gql`
        mutation {
          registerQueryShortLink (
            url:"${params.url}"
          )
          {
            data 
            status
            success
            message     
          }
        }   
      `,
    };

    GRAPHQL.doMutate(query)
      .then((response) => {
        try {
          if (response.data.registerQueryShortLink.success === "false") {
            throw Error(response.data.registerQueryShortLink.message);
          }
          return callback({
            status: 1,
            data: response.data.registerQueryShortLink.data,
          });
        } catch (e) {
          return callback({ status: 0, message: e.message });
        }
      })
      .catch((error) => {
        return callback({ status: 0, message: error });
      });
  }
}
