import { Fragment } from "react";
import Layout from "../../../layouts/Layout";
import { Link, useParams } from "react-router-dom";
const successImg = "/assets/demos/success.png";
const failedImage = "/assets/demos/failure.png";
const PurchaseResultFailed = ({result = false}) => {   
  const {id} = useParams();
  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="cart-main-area pt-90 pb-100">
          <div className="container justify-content-center align-items-center">
            <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
                {result == true && <img src={
                                        process.env.PUBLIC_URL +
                                        successImg
                                      } width={70}></img>}
                {result == false && <img src={
                                        process.env.PUBLIC_URL +
                                        failedImage
                                      } width={70}></img>}
            </div>
            <div className="row justify-content-center align-items-center pt-50" style={{textAlign: "center"}}>
                {result == true && <h4>You are eligible to buy this product</h4>}
                {result == false && <h4>You are not eligible to buy this product</h4>}
            </div>
            <div className="justify-content-center align-items-center pt-20" style={{textAlign: "center"}}>
                <button className="btn-purchase"><Link reloadDocument to={process.env.PUBLIC_URL + "/purchase" + id} style={{color: "white", padding: "10px"}}>
                    Search another Product
                </Link></button>
            </div>    
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export default PurchaseResultFailed;
