import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
const backgroundImg = "/assets/demos/confetti.png";
const successIcon = "/assets/demos/success.png";
const failureIcon = "/assets/demos/failure.png";
const VOTE_VALUE = [
  "YES", "NO", "Abstain"
]
function SuccessModal({show, onHide, answer, qrcode}) {

  const onCloseModal = () => {
    onHide()
  }

  return (
    <Modal show={show} onHide={onCloseModal} className="successModal">    
      <div className="modal-body">
        <div style={{width: "100%", height: "150px", display:'flex',flexDirection:'column',justifyContent:'center',  backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `${VOTE_VALUE[answer-1]? 'url("' + process.env.PUBLIC_URL+backgroundImg + '"})' : "none"}`}}>
          <div className="justify-content-center align-items-center" style={{textAlign: "center",display:'flex' }}>
            <img src={`${process.env.PUBLIC_URL}${VOTE_VALUE[answer-1] ? successIcon: failureIcon}`} width={50}/></div>
            <h4 style={{textAlign: "center", marginTop:'25px'}}>{`You voted ${VOTE_VALUE[answer-1] ? VOTE_VALUE[answer-1] : "Invalid value"}.`}</h4>
        </div>
        {/* <div className="row">
          <span style={{color: "#333333", fontSize: "12px", textAlign:"center"}}>You are eligible to buy these products. We hope you enjoy the purchasing journey.</span>
        </div> */}
     </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  qrcode: PropTypes.string
};

export default SuccessModal;
