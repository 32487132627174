import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "64ff2381b4fce10055665002" : "655335dc0635c10060dfda14";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const BuildingCheck = () => {
  const [modalShow, setModalShow] = useState(false);  
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");      
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const [deviceId, setDeviceId] = useState("");      
  const navigate = useNavigate();  
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [deeplinkContent, SetDeeplinkContent] = useState("");      
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDateTime = currentDateTime.toLocaleDateString('en-US', options);
  const time = formattedDateTime.split('at')[1];
  const week = formattedDateTime.split('at')[0].split(",")[0];
  const date = formattedDateTime.split('at')[0].split(",")[1];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // Update every mintue

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {            
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('result', result);
            //sessionId = ""                                    
            if (result){       

              const name = result.split("Name: ")[1].split("\n")[0];
              const email = result.split("Email: ")[1].split("\n")[0];
              const phone = result.split("Phone: ")[1].split("\n")[0];

              navigate(process.env.PUBLIC_URL + "/building/success", {state: {name, email, phone}});
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const checkDeviceExist = (device_id) => {    
    axios.get(`https://demo-backend.sharetoken.io/device/${device_id}`).then((response) => {
      console.log("checkDeviceExist", response);
      if (response.data && response.data.data){
        navigate(process.env.PUBLIC_URL + "/device/claimed", {deviceId: null});
      } else {
        setModalShow(true);        
      }      
    });
  }

  const onSubmit = (cronosAddress) => {
    console.log('cronosAddress', cronosAddress, deviceId);
    setModalShow(false);
    axios.post("https://demo-backend.sharetoken.io/device", {deviceId, cronosAddress}).then((response) => {
      navigate(process.env.PUBLIC_URL + "/device/success", {deviceId});
    });
  }

  return (
    <div style={{height:'100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url("/assets/img/BG.png")`}}>            
        {screenSize.width > 760 && <Fragment>
          <div style={{display: 'flex', justifyContent:'flex-end', color: "white", fontFamily: "Avenir", paddingTop: "30px"}}>            
            <div style={{width: "220px", textAlign: "right", padding: "10px"}}>
              <div style={{borderRight: "2px solid rgba(255, 255, 255, 0.2)", paddingRight: "20px"}}>
                <span style={{fontSize: "16px"}}>{date}</span><br/>
                <span style={{fontSize: "24px"}}>{week}</span>
              </div>
            </div>                                                            
            <div style={{width: "230px", textAlign: "right", padding: "20px", paddingTop: "30px", paddingLeft: "0px", fontSize: "48px"}}>
              <span>{time}</span>
            </div>              
          </div>
          <div className="justify-content-center align-items-center" style={{ left: "50%", position: "absolute", top: "60%",  transform: "translate(-50%, -50%)", padding: "10px"}}>
            <div className="row">
              <h3 style={{textAlign: "center", fontSize: "80px", color: "white", fontFamily: "Big Caslon"}}>{`WELCOME`}</h3>
            </div>
            <div className="row" style={{paddingTop: "50px"}}>
              <h3 style={{textAlign: "center", fontSize: "20px", color: "white", fontFamily: "Avenir"}}>{`Please scan the QR code to check in!`}</h3>
            </div>
            <div className="row justify-content-center align-items-center" style={{ textAlign: "center", padding: "20px" }}>
              <div style={{ width: "264px", height: "264px", margin: "0px", borderWidth: "0px", padding: "4px", curosr: "pointer", background: "white" }} onClick={goDeepLink}>
                {qrcodeShortLink&&<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}
              </div>
            </div>
          </div>
          <div className="justify-content-center align-items-center" style={{ left: "50%", position: "absolute",bottom:'0px', transform: "translate(-50%, -50%)"}}>
            <div className="row" style={{textAlign:"center", color: "white",  marginBottom: "10px"}}>
              <img src="/assets/img/logo.png" style={{width: "50px", margin: "auto"}}/>            
            </div>      
            <div className="row" style={{textAlign:"center", color: "white"}}>
              <span>Powered By ShareRing</span>  
            </div>            
          </div>
        </Fragment>}
        {screenSize.width <= 760 && <Fragment>
          <div className="row justify-content-center align-items-center">
            <div className="row" style={{marginTop: "150px"}}>
              <h4 style={{textAlign: "center", color: "white"}}>{`Please scan the QR code to check in!`}</h4>
            </div>
            <div className="row">
              <button className="btn-purchase" onClick={() => goDeepLink("identifi_me")} style={{ width: "250px", height: "40px", margin: "20px auto"}} title="Verify with ShareRing Me">Verify with ShareRing Me</button>
            </div>
            {/* <div className="row">
              <button className="btn-purchase" onClick={() => goDeepLink("sharering_pro")} style={{ width: "250px", height: "40px", margin: "auto" }} title="Verify with ShareRing Pro">Verify with ShareRing Pro</button>
            </div> */}
          </div>
        </Fragment>}  
                     
    </div>
  );
};

export default BuildingCheck;
