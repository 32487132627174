import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { Modal } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";

const cryptosTemp = [
  {name :"Bitcoin", currency: "BTC",  price: 37000,max:1.2, value:0,icon:'/assets/img/cryptoExchange/btc.png'},
  {name :"Ethereum", currency: "ETH",  price: 2000, max:30, value:0,icon:'/assets/img/cryptoExchange/eth.png'},
  {name :"ShareToken", currency: "SHR", price: 0.01, max:205, value:0,icon:'/assets/img/cryptoExchange/share.png'},
]

const Result = () => {
  const navigate = useNavigate();
  const [sendToken, setSendToken] = useState({name :"Bitcoin",currency:'BTC',value:0.5, max:1.2, icon:'/assets/img/cryptoExchange/btc.png'});      
  const [getToken, setGetToken] = useState({name :"Ethereum", currency:'ETH',value:0, max: 30, icon:'/assets/img/cryptoExchange/eth.png'});           
  const [rate, setRate] = useState('');           
  const [showModal, setShowModal] = useState(false);           
  const [cryptos, setCryptos] = useState(cryptosTemp);           
  const [selectOption, setSelectOption] = useState();           

  useEffect(() => {
    convertFromTo(sendToken,getToken)
    calcRate(sendToken,getToken)
  }, []);

  const calcRate = (from, to)=>{
    const source =cryptos.find(o=>from.currency == o.currency)
    const target =cryptos.find(o=>to.currency == o.currency)
    const fromUSDT = source.price
    const rate = (fromUSDT/target.price).toFixed(2)
    setRate(rate)
  }

  const convertFromTo = (from, to)=>{
    const source =cryptos.find(o=>from.currency == o.currency)
    const target =cryptos.find(o=>to.currency == o.currency)
    const fromUSDT = from.value * source.price
    const toValue = (fromUSDT/target.price).toFixed(2)
    let getTokenClone = to //JSON.parse(JSON.stringify(getToken))
    getTokenClone.value = toValue
    setGetToken (getTokenClone)
  }

  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/crypto-exchange/result", {state: {success: true}});
  };
  const handleMaxClick = ()=>{
    let sendTokenClone = JSON.parse(JSON.stringify(sendToken))
    sendTokenClone.value= sendTokenClone.max
    setSendToken(sendTokenClone)
    convertFromTo(sendTokenClone,getToken)
  }
  const onChangeInput = (e)=>{
    let sendTokenClone = JSON.parse(JSON.stringify(sendToken))
    if(e.target.value<=sendTokenClone.max){
      sendTokenClone.value= e.target.value
      setSendToken(sendTokenClone)
      convertFromTo(sendTokenClone,getToken)
    }
  }
  const onSwap = ()=>{
    let sendTokenClone = JSON.parse(JSON.stringify(sendToken))
    let getTokenClone = JSON.parse(JSON.stringify(getToken))
    sendTokenClone.value=0
    getTokenClone.value=0
    setSendToken(getTokenClone)
    setGetToken(sendTokenClone)
    calcRate(getTokenClone,sendTokenClone)
  }
  
  const onSelectSendToken = (item)=>{
    let selectedToken = item
    selectedToken.value = 0
    let target = {}
    if(selectedToken.currency == 'BTC'){
      target =cryptos.find(o=> o.currency == "ETH")
    }else{
      target =cryptos.find(o=> o.currency == "BTC")
    }
    if (selectOption=='from'){
      setSendToken(selectedToken)
      if(selectedToken.currency == getToken.currency){
        setGetToken(target)
        calcRate(selectedToken,target)
      }else{
        getToken.value=0
        setGetToken(getToken)
        calcRate(selectedToken,getToken)
      }
    }else{
      if(selectedToken.currency == sendToken.currency){
        target.value=0
        setSendToken(target)
        calcRate(target,selectedToken)
        setGetToken(selectedToken)
      }else{
        convertFromTo(sendToken,selectedToken)
        calcRate(sendToken,selectedToken)
      }
    }
    setShowModal(false)
  }
  const onShowTokenModal = (data)=>{
    setSelectOption(data)
    setShowModal(true)
  }

  const onCloseModal = ()=>{
    setShowModal(false)
  }
  
  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="crypto-exchange-area" 
          style={{
            backgroundImage: "url('/assets/img/cryptoExchange/background2.png')",
            position:"absolute",
          }}
          > 
            <div className="exchange">
              <div className="crypto-card">
                <div className="top-row">
                  <div className="ctypto-selector-cover" >
                    <div className="exchange-title" >YOU SEND</div>
                    <div className="ctypto-selector" onClick={()=>onShowTokenModal('from')}>
                      <img className="btc-img" alt="" src={sendToken.icon} />
                      <div className="name">{sendToken.currency}</div>
                      <img className="arrow-img" alt="" src={"/assets/img/cryptoExchange/arrow.png"} />
                    </div>
                    <div className="ctypto-name">{sendToken.name}</div>
                  </div>
                  <input
                    className="exchange-value"
                    onChange={(e) => {onChangeInput(e) }}
                    value={sendToken.value}
                />
                </div>
                <div className="separator"></div>
                <div className="bottom-row">
                  <div className="balance" >
                    Balance
                  </div>
                  <div className="cypto-value">
                    <div> {sendToken.max} {sendToken.currency}</div>
                    <button className="btn-max"
                        onClick={handleMaxClick}>
                        Max
                    </button>
                  </div>
                </div>
              </div>
              <img  onClick={onSwap} className="switch-img" alt="" src={"/assets/img/cryptoExchange/switch.png"} />

              <div className="crypto-card">
                <div className="top-row">
                  <div className="ctypto-selector-cover" onClick={()=>onShowTokenModal('to')}>
                    <div className="exchange-title" >YOU GET</div>
                    <div className="ctypto-selector">
                      <img className="btc-img" alt="" src={getToken.icon} />
                      <div className="name">{getToken.currency}</div>
                      <img className="arrow-img" alt="" src={"/assets/img/cryptoExchange/arrow.png"} />
                    </div>
                    <div className="ctypto-name">{getToken.name}</div>
                  </div>
                  <div className="exchange-value">
                    {getToken.value}
                  </div>
                </div>
                <div className="separator"></div>
                <div className="bottom-row">
                  <div className="balance" >
                    Balance
                  </div>
                  <div className="cypto-value">
                    <div> {getToken.max} {getToken.currency} </div>
                  </div>
                </div>
              </div>
              <div className="rate-value">Rate: 1 {sendToken.currency} = {rate} {getToken.currency}</div>
              <button className="btn-exchange"
                onClick={handleClick}>
                Exchange
              </button>
              <div className="bottom-logo">
                  <img  alt="" width={26} src="/assets/img/hotel/logo.png"/>
                  <div className="logo-text">
                      Powered by ShareRing
                  </div>
              </div>
            </div>
            <Modal show={showModal} onHide={onCloseModal} className="successModal hop-success-modal">    
              <div className="modal-body" style={{height:'500px'}}>
                {/* <div class="search-container" style={{display:'flex',alignItems:'center',}} >
                  <input 
                    type="text" 
                    id="search-input" 
                    class="search-input" 
                    style={{backgroundImage: "url(" + "/assets/img/cart/search.png" + ")" ,borderRadius:'8px', padding:'10px 30px 10px 10px', background:'no-repeat', backgroundSize:'20px 20px', backgroundPosition:'95% center', border:'1px solid #676d7f'}}
                    placeholder="Search token..."/>
                </div> */}
                {cryptos.map((item, idx)=>{
                  return(
                    <div onClick={()=>onSelectSendToken(item)} style={{display:'flex', flexDirection:'row', alignItems:'center', height:'50px', cursor:'pointer', }} key= {idx}>
                      <img style={{width:'30px'}} className="token-img" alt="" src={item.icon} />
                      <div style={{marginLeft:'10px'}}>{item.currency} <span style={{color:'#439b46'}}>({item.name}) </span></div>
                    </div>
                  )
                })
                }
             </div>
            </Modal>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Result;
