import {  useEffect } from "react";

const QrcodeSuccess = ({number, handleClick}) => {
  const handleBtnClick = (e) => {
    handleClick(e) 
  };
  useEffect(() => {
  }, []);
  return ( 
    <div className="content" style={{paddingLeft:0, justifyContent: 'center'}}>
      <div className="left-section-mobile">
        <div className="left-section-content" >
          <div className="top-border">
            <div className="border1"/>
            <div className="border2"/>
            <div className="border1"/>
          </div>
          <img alt="" width={112} src="/assets/img/hotel/thank.png" />
          <div className="desscription"
            style={{ fontWeight:300}}
            >
            {"We hope you enjoy your stay."}
          </div>   
          <div className="bottom-logo">
            <img alt="" width={26} src="/assets/img/hotel/logo.png"/>
            <div style={{marginTop:'10px'}}>
              Powered by ShareRing
            </div>
          </div>
          <div className="right-section">
            <div className="right-section-content">
              <div className="title">
                Checked in successfully
              </div> 
              <button className="btn-gradient"
                onClick={e => handleBtnClick(e)}>
                Back to home screen
              </button>
              <div className="desscription">
                Auto backing to home screen after {number} seconds
              </div> 
            </div>
          </div>
          <div className="bottom-border"/>
        </div>
      </div>  
    </div>     
  )
}
export default QrcodeSuccess;
