import PropTypes, { node } from "prop-types";
import { Modal } from "react-bootstrap";
import ConfettiCanvas from 'react-confetti-canvas';

const backgroundImg = "/assets/demos/confetti.png";
const successIcon = "/assets/demos/success.png";
function SuccessModal({show, onHide, onVerify,age, qrcode}) {

  const onCloseModal = () => {
    onHide()
  }
  const confettiStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    /* Your custom styles go here */
  };
  return (
    <Modal show={show} onHide={onCloseModal} className="successModal hop-success-modal">    
      <ConfettiCanvas
        colors = {[
          ["#df0049", "#660671"],
          ["#00e857", "#005291"],
          ["#2bebbc", "#05798a"],
          ["#ffd200", "#b06c00"]
        ]}
      />
      <div className="modal-body">
        <div style={{width: "100%", height: "100px"}}>
          <div className="justify-content-center align-items-center" style={{textAlign: "center"}}>
            <img src={`${process.env.PUBLIC_URL}${successIcon}`} width={50}/>
          </div>
          <h4 style={{marginTop:"20px", fontSize: "20px", textAlign: "center"}}>{`Age verified successfully`}</h4>
        </div>
        <div className="row">
          <span style={{marginTop:"10px", color: "#333333", fontSize: "16px", textAlign:"center", fontWeight:300}}>You are eligible to buy these products. Happy shopping!</span>
        </div>          
        <div className="result-modal-shop" style={{display:"flex", justifyContent:'center', marginTop:'20px'}}>
          <button className="btn-in-shop" onClick={() => onVerify()}>Ok</button>
        </div>
     </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  qrcode: PropTypes.string
};

export default SuccessModal;
