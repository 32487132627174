import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import { useNavigate } from "react-router-dom";

import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "652520ed54f5ff005640fa7a" : "65533c7af2cc1100605dde8e";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Welcome = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");   
  const [status, setStatus] = useState(0);  
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const [deeplinkContent, SetDeeplinkContent] = useState("");      

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {            
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('result', result);
            //sessionId = ""                                    
            if (result){       
              const name = result.split("Name: ")[1].split("\n")[0];
              const dob = result.split("Dob: ")[1].split("\n")[0];
              const nationality = result.split("Nationality: ")[1].split("\n")[0];
              const gender = result.split("Gender: ")[1].split("\n")[0];
              const civil_status = result.split("Civil Status: ")[1].split("\n")[0];
              const place_of_birth = result.split("Place of Birth: ")[1].split("\n")[0];
              const type_of_travel_document = result.split("Type of Travel Document: ")[1].split("\n")[0];
              const document_id_number = result.split("Document Id Number: ")[1].split("\n")[0];
              const country = result.split("Country: ")[1];
              navigate(process.env.PUBLIC_URL + "/visa/result", {state: {name, dob, country, nationality, gender, civil_status, place_of_birth, type_of_travel_document, document_id_number}});
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };


  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="visa-area" 
          style={{
            backgroundImage: "url('/assets/img/visa/background_2.png')",
            position:"absolute",
          }}
          > 
            <div className="content">
              <div className="content-body">
                <div className="description">
                  To process your visa application please 
                  <span style={{fontWeight:500}}> scan the QR code </span>
                  and provide the relevant information.
                </div>
                <div className="qrcode-section">
                  <div style={{ width: "260px", height: "260px", margin: "auto", borderWidth: "4px", borderColor: "#fff", curosr: "pointer" }} onClick={goDeepLink}>
                    {qrCode&&<QRCode size={256} value={qrCode} viewBox={`0 0 256 256`} />}
                  </div>            
                  <div className="info-text">
                    <div className="title"> Your Information</div>
                    <ul>
                      <li className="info"> Full Name</li>
                      <li className="info"> Date of Birth</li>
                      <li className="info"> Nationality</li>
                      <li className="info"> Gender</li>
                      <li className="info"> Civil Status</li>
                    </ul>
                  </div> 
                </div>
              </div>
              <div className="bottom-logo">
                  <img alt="" width={26} src="/assets/img/visa/black_logo.png"/>
                  <div className="logo-text">
                      Powered by ShareRing
                  </div>
              </div>
            </div>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Welcome;
