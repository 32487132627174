import Grid from '@mui/material/Grid';

const BottomSection = ({demos, onClickDemo}) => {

    return (  
        <div className="bottom-section">
            <div className="title">
                Our demos
            </div>
            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {demos.map((item,idx)=>{
                return(
                    <Grid  onClick={(e)=>onClickDemo(e,item)} key={idx} item xs={12} sm={6} md={4} lg={3}>
                        <div className="demo">
                            <div className='demo-img-cover'>
                                <img className="demo-img" alt="" src={item.img} />
                            </div>
                            <div className="demo-title">{item.title} </div>
                            <div className="description"> {item.description} </div>
                        </div>
                        
                    </Grid>)})}
            </Grid> 
        </div>   
    )
}

export default BottomSection;
