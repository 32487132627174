import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const Welcome = ({qrCode, goDeepLink}) => {

  useEffect(() => {
  }, []);

  return (  
    <div className="content">
      <div className="left-section">
        <div className="left-section-content">
          <div className="top-border">
            <div className="border1"/>
            <div className="border2"/>
            <div className="border1"/>
          </div>
          <img alt="" width={112} src="/assets/img/hotel/welcome.png" />
          <div className="desscription"
            style={{ fontWeight:300}}
            >
            {" To check in to your room, please "}
            <span style={{ fontWeight:600 }}>
              {'scan this QR code'}
            </span>
          </div>   
          <div className="bottom-border"/>
          <div className="bottom-logo">
            <img  alt="" width={26} src="/assets/img/hotel/logo.png"/>
            <div style={{marginTop:'10px'}}>
              Powered by ShareRing
            </div>
          </div>
        </div>  
      </div>  
      <div className="right-section">
        <div className="right-section-border">
          {qrCode&&<div className="qrcode-section">
            <div style={{ width: "260px", height: "260px", margin: "auto", borderWidth: "4px", borderColor: "#fff", curosr: "pointer" }} onClick={goDeepLink}>
              {<QRCode size={256} value={qrCode} viewBox={`0 0 256 256`} />}
            </div>            
          </div> }
        </div>   
      </div>
    </div>     
  )
}

export default Welcome;
