import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";

function VerifyModal({show, onHide, age, qrcode}) {
  console.log('qrcode', qrcode);
  const onCloseModal = () => {
    onHide()
  }

  return (
    <Modal show={show} onHide={onCloseModal} className="verifyModal">    
      <div className="modal-body">
        <div className="row">
          <h4 style={{textAlign: "center"}}>{`Are you over ${age} years old?`}</h4>
        </div>
        <div className="row justify-content-center align-items-center" style={{ textAlign: "center", background: "white", padding: "20px" }}>
            <div style={{ width: "260px", height: "260px", margin: "auto", borderWidth: "4px", borderColor: "#fff" }}>{<QRCode size={256} value={qrcode} viewBox={`0 0 256 256`} />}</div>
        </div>
        <div className="row">
          <span style={{textAlign:"center", color: "#909090"}}>Please use sharering app to scan this QR code.</span>
        </div>
     </div>
    </Modal>
  );
}

VerifyModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  qrcode: PropTypes.string
};

export default VerifyModal;
