import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import QrSuccess from "../../../components/hotel/QrSuccess";
import QrSuccessMobile from "../../../components/hotel/QrSuccessMobile";

const QrcodeSuccess = () => {
  const navigate = useNavigate();  
  const [seconds, setSeconds] = useState(5);  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    // Decrease the seconds every 1 second
    const intervalCounter = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);      
    }, 1000);

    // Clean up the interval and stop when seconds reach 0
    return () => {
      clearInterval(intervalCounter);
    };
  }, []);

  useEffect(() => {
    // Add a resize event listener when the component mounts
    window.addEventListener('resize', handleResize);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs once after mount
  
  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/hotel/welcome", { });
    window.location.reload();
  };

  useEffect(() => {
    if (seconds <= 0) {      
      navigate('/hotel/welcome');
      window.location.reload();
    }
  }, [seconds, navigate]);

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="hotel-area" 
          style={{
            backgroundImage: "url('/assets/img/hotel/room.png')",
            position:"absolute",
          }}
          > 
        {screenWidth>900?
          <QrSuccess
            number={seconds}
            handleClick={handleClick}
          />:
          <QrSuccessMobile
            number={seconds}
            handleClick={handleClick}/>}
        </div>
      </Layout>
    </Fragment>
  )
}
export default QrcodeSuccess;
