import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { useNavigate, useLocation } from "react-router-dom";

const Result = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);  
  const [status, setStatus] = useState(true);      

  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    // Decrease the seconds every 1 second
    const intervalCounter = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);      
    }, 1000);

    // Clean up the interval and stop when seconds reach 0
    return () => {
      clearInterval(intervalCounter);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {      
      navigate('/crypto-exchange/welcome');
      window.location.reload();
    }
  }, [seconds, navigate]);

  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/crypto-exchange/welcome", { });
    window.location.reload();
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="night-club-area" 
          style={{
            backgroundImage: "url('/assets/img/cryptoExchange/background2.png')",
            position:"absolute",
          }}
          > 
            <div className="content">            
                <div style={{fontSize:'32px'}}> {data.success ? "Request sent successfully!" : "Verification Failed!" } </div>
                <button className="btn-gradient" onClick={e => handleClick(e)}>
                  Back to home screen
                </button>
                <div className="result-screen-text">
                  Auto backing to home screen after {seconds} seconds
                </div>
                <div className="bottom-logo">
                    <img  alt="" width={26} src="/assets/img/hotel/logo.png"/>
                    <div className="logo-text">
                        Powered by ShareRing
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Result;
