import Grid from '@mui/material/Grid';

const MiddleSection = ({onClickGoogle,onClickApple}) => {

  return ( 
    <div className="middle-content">
      <div className="title">
        Experience our demos with ShareRing Me
      </div>
      <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={8}>
          <div className="middle-left" >
            <div className="steps">
              Simply follow these steps:
            </div>
            <div className="short_horizontal_line"/>
            <div className="step"> 
              <div className="number">
                01
              </div>
              <div className="description">
                Download and install ShareRing Me on your mobile device
              </div>
            </div>
            <div className="step"> 
              <div className="number">
                02
              </div>
              <div className="description">
                Open ShareRing Me and setup your account
              </div>
            </div>
            <div className="step"> 
              <div className="number">
                03
              </div>
              <div className="description">
                Scan the QR code from one of the demo pages with the app
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="img-group">
          <img className="logo" alt="" src="/assets/img/home/logoIcon.png" />
          <img onClick={()=>onClickGoogle()} className="google" alt="" src="/assets/img/home/googlePlay.png" />
          <img onClick={()=>onClickApple()} className="apple" alt="" src="/assets/img/home/appStore.png" />
        </Grid>
      </Grid> 
    </div>
      
  )
}
export default MiddleSection;
