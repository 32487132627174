import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV
} from "../../../services/graphql/config";
import VerifyModal from "./VerifyModal";

const cryptoJS = require("crypto-js");
const AGE_QUERY_ID = ENV ? "645bb902d9b71d005e0caaa2" : "6553379f7a959a006172c6c9";
let sessionId = "";

const Delivery100 = () => {
  const [modalVerifyShow, setVerifyModalShow] = useState(false);  
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");    
  const navigate = useNavigate();  
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const goDeepLink = () => {
    console.log('goDeepLink');
    if (qrCode)
      window.open(qrCode, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        let data =
          DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${AGE_QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {
    console.log('onGetQRRequests', status);
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: AGE_QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };
    console.log('onGetQRRequests', params);
    APIServices.getQRRequestItems(params, (response) => {
      console.log('response', response);
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            console.log('--response', response.data.items[0].result,
              SECURITY_WORD);
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );
            console.log('data', data);
            let age = JSON.parse(data).qrRes;              
            console.log('qrData', age);
            //sessionId = ""
            setVerifyModalShow(false);
            if (age == "YES"){
              setStatus(1);              
              navigate(process.env.PUBLIC_URL + "/delivery/result/success", {result1: false});
            } else {
              setStatus(-1);
              navigate(process.env.PUBLIC_URL + "/delivery/result/failed", {result1: false});
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <Fragment>      
      <div>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          style={{ height: "30px", width: "30px" }}
        />
      </div>
      <div className="justify-content-center align-items-center" style={{ left: "50%", position: "absolute", top: "50%",  transform: "translate(-50%, -50%)", padding: "10px"}}>
        {checked && <button className="btn-purchase" onClick={() => goDeepLink()} style={{width: "200px", height: "40px"}} title="Confirm delivery">Confirm delivery</button>}
        {!checked && <button className="btn-purchase" onClick={() => setVerifyModalShow(true)} style={{width: "200px", height: "40px"}} title="Confirm delivery">Confirm delivery</button>}
      </div>    
      <VerifyModal
          show={modalVerifyShow}
          onHide={() => setVerifyModalShow(false)}          
          qrcode={qrCode}
          age={100}    
      />
    </Fragment>
  );
};

export default Delivery100;
