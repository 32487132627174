import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import SubmitModal from "./SubmitModal";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "64a233c04ecaf8005c5473d0" : "655337df7a959a006172c6e7";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const DeviceCheck = () => {
  const [modalShow, setModalShow] = useState(false);  
  const [status, setStatus] = useState(0);
  const [qrCode, setQrCode] = useState("");      
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const [deviceId, setDeviceId] = useState("");      
  const navigate = useNavigate();  
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [deeplinkContent, SetDeeplinkContent] = useState("");      
  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {            
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let device_Id = JSON.parse(data).qrRes;                          
            //sessionId = ""                                    
            if (device_Id){
              setDeviceId(device_Id);
              checkDeviceExist(device_Id);
              setStatus(1);              
              //navigate(process.env.PUBLIC_URL + "/device/success", {deviceId});
            } else {
              setStatus(-1);
              navigate(process.env.PUBLIC_URL + "/device/claimed", {deviceId: null});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const checkDeviceExist = (device_id) => {    
    // axios.get(`https://demo-backend.sharetoken.io/device/${device_id}`).then((response) => {
    //   console.log("checkDeviceExist", response);
    //   if (response.data && response.data.data){
    //     navigate(process.env.PUBLIC_URL + "/device/claimed", {deviceId: null});
    //   } else {
    //     setModalShow(true);        
    //   }      
    // });
    setModalShow(true);
  }

  const onSubmit = (cronosAddress) => {
    // console.log('cronosAddress', cronosAddress, deviceId);
    // setModalShow(false);
    // axios.post("https://demo-backend.sharetoken.io/device", {deviceId, cronosAddress}).then((response) => {
    //   navigate(process.env.PUBLIC_URL + "/device/success", {deviceId});
    // });
    navigate(process.env.PUBLIC_URL + "/device/success", {deviceId});
  }

  return (
    <Fragment>      
        {screenSize.width > 768 && <Fragment>
          <div 
            className="device-area"
            style={{
              backgroundImage: "url('/assets/img/device/background.png')",
              backgroundSize: "cover",
              color: 'white',
              width:'100%', height:'100%', 
              position: "absolute",  display:'flex',justifyContent:'center',alignItems:'center'
            }}
          > 
            <div style={{display: 'flex',flexDirection: 'column',alignItems: 'center',position: 'absolute',bottom: '20px'}}>
              <img alt="" width={26} src="/assets/img/hotel/logo.png"/>
              <div style={{marginTop:"10px"}}>
                  Powered by ShareRing
              </div>
            </div>
            <div className="content" style={{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              padding:'60px', 
              flexDirection:'column',
              // border : "2px solid #47A5F1",
              borderRadius:'16px',
              background: 'rgba(0,0,0,0.5)',
              backdropFilter: 'blur(2px)'
              }}>
              <div className="">
                <h3 style={{textAlign: "center",fontSize:'48px', fontWeight:600, color:'#47A5F1'}}>{`VERIFY DEVICE`}</h3>
              </div>
              <div style={{maxWidth:'330px', textAlign:"center",marginTop:'20px'}}>
                <span style={{ color: "#F4F2F3", fontSize:'24px', lineHeight:'33px'}}>Please scan the QR code to verify your device</span>
              </div> 
              <div style={{ textAlign: "center", background: "white", padding: "30px",marginTop:'20px', borderRadius:'10px' }}>
                <div style={{ width: "260px", height: "260px", margin: "auto", borderWidth: "4px", borderColor: "#fff", cursor: "pointer" }} onClick={goDeepLink}>
                  {qrcodeShortLink&&<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}
                </div>
              </div>
            </div>
        </div>
        </Fragment>}
        {screenSize.width <= 768 && <Fragment>
          <div className="row justify-content-center align-items-center">
            <div className="row" style={{marginTop: "150px"}}>
              <h4 style={{textAlign: "center", fontSize:'48px', fontWeight:600, color:'#47A5F1'}}>{`VERIFY DEVICE`}</h4>
            </div>
            <div className="row">
              <button className="btn-purchase" onClick={() => goDeepLink("identifi_me")} style={{ width: "250px", height: "40px", margin: "20px auto"}} title="Verify with ShareRing Me">Verify with ShareRing Me</button>
            </div>
            <div className="row">
              <button className="btn-purchase" onClick={() => goDeepLink("sharering_pro")} style={{ width: "250px", height: "40px", margin: "auto" }} title="Verify with ShareRing Pro">Verify with ShareRing Pro</button>
            </div>
          </div>
        </Fragment>}     
      <SubmitModal
          show={modalShow}
          onSubmit={(cronosAddress) => {onSubmit(cronosAddress)}}                              
      />
    </Fragment>
  );
};

export default DeviceCheck;
