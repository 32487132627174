import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "641275f07cd216005d5481cc" : "65532d3c441d260061eaf201";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Age = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");
  const [status, setStatus] = useState(0);
  const [qrcodeShortLink, setQrShortLink] = useState("");
  const [deeplinkContent, SetDeeplinkContent] = useState("");
  const [success, setSuccess] = useState(false);  
  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me") {
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };
    APIServices.getQRRequestItems(params, (response) => {
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );
            let result = JSON.parse(data).qrRes;
            console.log('data', data);
            console.log('result', result);
            //sessionId = ""                                    
            if (result) {
              const res = result;
              console.log('res', res);
              //navigate(process.env.PUBLIC_URL + "/vote/result", {state: {answer}});
              setStatus(1);
              if (res == 'Yes') {
                setSuccess(true);
              } else {
                setSuccess(false);
              }
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const onHome = () => {
    navigate('/age');
    window.location.reload();
  }

  return (
    <div class="content age">
      {status == 0 && <div class="main d-flex align-items-center">
        <div class="background" style={background}></div>
        <div class="qrcode-container d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <div class="qrcode-header text-center" style={{ marginBottom: "50px" }}>
            <h1 class="title text-center" style={title}>Scan this QR code by ShareRing<br />app to verify your age</h1>
            <span class="subtitle" style={subtitle}>Your information won’t be sent to this site</span>
          </div>
          <div className="qrcode-section">
              <div style={{ width: "264px", height: "264px", margin: "0px", borderWidth: "0px", padding: "4px", curosr: "pointer", background: "white" }} onClick={goDeepLink}>
                {qrcodeShortLink&&<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}
              </div>
          </div>
        </div>
      </div>}
      {status != 0 && success == true && <div class="success container d-flex justify-content-center">
        <div class="message-box" style={messageBox}>
          <img class="icon mb-4" src="/demos/age/assets/img/success.png" style={messageBoxIcon}/>
          <span class="message mb-4" style={messageBoxMessage}>
            Age verified successfully.<br />
            You can access this website
          </span>
          <button class="btn btn-custom btn-ok" onClick={() => onHome()}>Got it</button>
        </div>
      </div>}
      {status != 0 && success == false && <div class="error container d-flex justify-content-center">
        <div class="message-box" style={messageBox}>
          <img class="icon mb-4" src="/demos/age/assets/img/error.png" style={messageBoxIcon}/>
          <span class="message mb-4" style={messageBoxMessage}> This website is not available for someone who is under 18 </span>
          <button class="btn btn-custom btn-ok" onClick={() => onHome()}>Got it</button>
        </div>
      </div>}
    </div>
  );
};

export default Age;

// Styling
const container = {
  width: 500,
  margin: '50px auto'
}

const background = {
  height: "100vh",
  width: "50%",
  background: "url('/demos/age/assets/img/background.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
}

const title = {
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "33px",
  color: "#333333"
}

const subtitle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#48c4a1"
}

const messageBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px",
  width: "600px",
  backgroundColor: "white",
  borderRadius: "8px",
  marginTop: "120px"
}

const messageBoxIcon = {
  width: "80px"
}

const messageBoxMessage = {
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "33px",
  color: "#333333",
  textAlign: "center"
}
