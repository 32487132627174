import { Fragment } from "react";
import {Form, Button} from 'react-bootstrap';
const DeviceCheckSuccess = () => {     
  return (
    <Fragment>      
      <div className="row" style={{position:"absolute", height: "100%", width: "100%", background: "green"}}>
      </div>
      <div className="justify-content-center align-items-center" style={{ textAlign: "center", left: "50%", position: "absolute", top: "50%",  transform: "translate(-50%, -50%)", padding: "10px"}}>
          <p style={{color: "white", fontSize: "15px"}}>Device verified</p>          
      </div>    
    </Fragment>
  );
};

export default DeviceCheckSuccess;
