import { Fragment, useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "655eb085dc5e550055523e4d" : "66028a2f2d0caeb268f23a0c";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";

const Welcome = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");   
  const [status, setStatus] = useState(0);  
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const [deeplinkContent, SetDeeplinkContent] = useState("");      

  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {            
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('result', result);
            //sessionId = ""                                    
            if (result){       
              const res = result;           
              const name = res.split("Name: ")[1].split("\n")[0];
              const date_of_birth = res.split("Date of Birthday: ")[1].split("\n")[0];
              const address = res.split("Address: ")[1].split("\n")[0];
              const photo = res.split("Photo: ")[1].split("\n")[0];                            
              navigate(process.env.PUBLIC_URL + "/crypto-exchange/exchange", {state: {name, date_of_birth, address, photo}});
            } else {
              setStatus(-1);
              navigate(process.env.PUBLIC_URL + "/crypto-exchange/result", {state: {success: false}});
            }

          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="crypto-exchange-area" 
          style={{
            backgroundImage: "url('/assets/img/cryptoExchange/background2.png')",
            position:"absolute",
          }}
          > 
            <div className="content">
                <div className="left-section">
                  <div className="title">
                    <div>CRYPTO</div>
                    <div>EXCHANGE</div>
                    <div >
                      <img className="left-logo" alt="" src="/assets/img/cryptoExchange/cryptoLogo.png"/>
                    </div> 
                  </div>
                </div>
                {qrCode?<div className="right-section">
                  <div className="description">
                    <div>Before using our platform we need to verify your identity. Please scan the QR code below and follow the steps for identity verification.</div>
                    <div >
                      <img className="right-back" alt="" src="/assets/img/cryptoExchange/patternQRcode.png"/>
                    </div> 
                    </div>
                  <div className="qrcode-section">
                    <div style={{ width: "260px", height: "260px", margin: "auto", borderWidth: "4px", borderColor: "#fff",cursor:'pointer'}} onClick={goDeepLink}>{<QRCode size={256} value={qrCode} viewBox={`0 0 256 256`} />}</div>              
                  </div>
                </div>:<div className="right-section"></div>}
                <div className="bottom-logo">
                  <img alt="" width={26} src="/assets/img/hotel/logo.png"/>
                  <div className="logo-text">
                      Powered by ShareRing
                  </div>
                </div>  
            </div>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Welcome;
