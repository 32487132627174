import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";

function VerifyModal({show, onHide, age, qrcode, goDeepLink}) {
  console.log('qrcode', qrcode);
  const onCloseModal = () => {
    onHide()
  }

  return (
    <Modal show={show} onHide={onCloseModal} className="verifyModal shop-verify-modal">    
      <div className="modal-body ">
        <div className="row">
          <h4 className="qr-modal-title">{`Are you over ${age} years old?`}</h4>
        </div>
        <div className="row">
          <span className="qr-modal-text" >Please use the ShareRing Me app to verify your age by scanning this QR code</span>
        </div>
        <div className="row justify-content-center align-items-center" style={{ textAlign: "center", background: "white", padding: "40px 20px 20px 20px" }}>
          <div style={{ width: "264px", height: "264px", margin: "0px", borderWidth: "0px", padding: "4px", curosr: "pointer", background: "white" }} onClick={goDeepLink}>{<QRCode size={256} value={qrcode} viewBox={`0 0 256 256`} />}</div>
        </div>
     </div>
    </Modal>
  );
}

VerifyModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  qrcode: PropTypes.string
};

export default VerifyModal;
