import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import PurchaseResultSuccess from "./pages/main/purchases/PurchaseResultSuccess";
import PurchaseResultFailed from "./pages/main/purchases/PurchaseResultFailed";
import DeliveryResultFailed from "./pages/main/delivery/DeliveryResultFailed";
import DeliveryResultSuccess from "./pages/main/delivery/DeliveryResultSuccess";
import Delivery100 from "./pages/main/delivery/Delivery100";
import DeviceCheck from "./pages/main/device/DeviceCheck";
import DeviceCheckSuccess from "./pages/main/device/DeviceCheckSuccess";
import DeviceCheckFailed from "./pages/main/device/DeviceCheckFailed";
import BuildingCheck from "./pages/main/building/BuildingCheck";
import BuildingCheckSuccess from "./pages/main/building/BuildingCheckSuccess";
import HotelWelcome from "./pages/main/hotel/Welcome";
import HotelResult from "./pages/main/hotel/Result";
import NightClubWelcome from "./pages/main/nightClub/Welcome";
import NightClubResult from "./pages/main/nightClub/Result";
import BankWelcome from "./pages/main/bank/Welcome";
import BankResult from "./pages/main/bank/Result";
import VisaWelcome from "./pages/main/visa/Welcome";
import VisaResult from "./pages/main/visa/Result";
import VoteWelcome from "./pages/main/vote/Welcome";
import Home from "./pages/main/home/Home";
import AgeWelcome from "./pages/main/age/Welcome";
import CurrencyExchange from "./pages/main/currencyExchange/Welcome";
import EndeavourHotelWelcome from "./pages/main/endeavourHotel/Welcome";
import EndeavourHotelResult from "./pages/main/endeavourHotel/Result";
import EndeavourAgeWelcome from "./pages/main/endeavourAge/Welcome";
import EndeavourAgeResult from "./pages/main/endeavourAge/Result";
import EndeavourShopWelcome from "./pages/main/endeavourShop/Welcome";
import EndeavourShopResult from "./pages/main/endeavourShop/Result";
import CryptoExchangeWelcome from "./pages/main/cryptoExchange/Welcome";
import CryptoExchange from "./pages/main/cryptoExchange/Exchange";
import CryptoExchangeResult from "./pages/main/cryptoExchange/Result";

const Delivery18 = lazy(() => import("./pages/main/delivery/Delivery18"));
//const Delivery100 = lazy(() => import("./pages/main/delivery/Delivery18"));
const Purchase18 = lazy(() => import("./pages/main/purchases/Purchase18"));
const Purchase100 = lazy(() => import("./pages/main/purchases/Purchase100"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<Home />}
            />
            {/* <Route
              path={process.env.PUBLIC_URL + "/endeavourHotel/welcome"}
              element={<EndeavourHotelWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/endeavourHotel/result"}
              element={<EndeavourHotelResult />}
            />
            
            <Route
              path={process.env.PUBLIC_URL + "/endeavourAge/welcome"}
              element={<EndeavourAgeWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/endeavourAge/result"}
              element={<EndeavourAgeResult />}
            /> */}

            <Route
              path={process.env.PUBLIC_URL + "/shop/welcome"}
              element={<EndeavourShopWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop/result"}
              element={<EndeavourShopResult />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/purchase18"}
              element={<Purchase18 />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/purchase100"}
              element={<Purchase100 />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/purchase/result/:id/failed"}
              element={<PurchaseResultFailed />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/purchase/result/:id/success"}
              element={<PurchaseResultSuccess />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/delivery18"}
              element={<Delivery18 />}
            />            

            <Route
              path={process.env.PUBLIC_URL + "/delivery100"}
              element={<Delivery100 />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/delivery/result/success"}
              element={<DeliveryResultSuccess />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/delivery/result/failed"}
              element={<DeliveryResultFailed />}
            />

            <Route  
              path={process.env.PUBLIC_URL + "/device/check"}
              element={<DeviceCheck />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/device/success"}
              element={<DeviceCheckSuccess />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/device/claimed"}
              element={<DeviceCheckFailed />}
            />

            <Route  
              path={process.env.PUBLIC_URL + "/building/check"}
              element={<BuildingCheck />}
            />

            <Route  
              path={process.env.PUBLIC_URL + "/building/success"}
              element={<BuildingCheckSuccess />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/hotel/welcome"}
              element={<HotelWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/hotel/result"}
              element={<HotelResult />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/nightclub/welcome"}
              element={<NightClubWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/nightclub/result"}
              element={<NightClubResult />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/bank/welcome"}
              element={<BankWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/bank/result"}
              element={<BankResult />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/visa/welcome"}
              element={<VisaWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/visa/result"}
              element={<VisaResult />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/vote/welcome"}
              element={<VoteWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/age"}
              element={<AgeWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/crypto-exchange/welcome"}
              element={<CryptoExchangeWelcome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/crypto-exchange/exchange"}
              element={<CryptoExchange />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/crypto-exchange/result"}
              element={<CryptoExchangeResult />}
            />
            {/* <Route path="*" element={<NotFound />} /> */}
            <Route path="*" element={<Navigate to={`/`} />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;