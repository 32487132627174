import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import InputForm from "../../../components/common/InputForm";

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const [fullName, setFullName] = useState(data?.name);      
  const [dateOfBirth, setDateOfBirth] = useState(data?.dob);      
  const [placeOfBirth, setPlaceOfBirth] = useState(data?.place_of_birth);      
  const [countryOfBirth, setCountryOfBirth] = useState(data?.country);      
  const [nationality, setNationality] = useState(data?.nationality);      
  const [gender, setGender] = useState(data?.gender);      
  const [civilStatus, setCivilStatus] = useState(data?.civil_status);      
  const [travelType, setTravelType] = useState(data?.type_of_travel_document);      
  const [docIdNumber, setDocIdNumber] = useState(data?.document_id_number);      

  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/visa/welcome", { });
  };
  const onChangeInput = (type,e) => {
    switch (type){
      case 'fullName':
        setFullName(e.target.value)
        break
      case 'dateOfBirth':
        setDateOfBirth(e.target.value)
        break
      case 'placeOfBirth':
        setPlaceOfBirth(e.target.value)
        break
      case 'countryOfBirth':
        setCountryOfBirth(e.target.value)
        break
      case 'nationality':
        setNationality(e.target.value)
        break
      case 'gender':
        setGender(e.target.value)
        break
      case 'civilStatus':
        setCivilStatus(e.target.value)
        break
      case 'travelType':
        setTravelType(e.target.value)
        break
      case 'docIdNumber':
        setDocIdNumber(e.target.value)
        break
      default:
        break
    }
  };
  
  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="visa-area" 
          style={{
            backgroundImage: "url('/assets/img/visa/background_2.png')",
            position:"absolute",
          }}
          > 
          <div className="content">
            <div className="info-section">
              <div className="info-title"> Your information</div>
              <div className="info-content">
                <div className="info-left">
                  <InputForm
                    label={"Full Name"}
                    inputValue={fullName}
                    changeInput={(e)=>onChangeInput('fullName',e)}
                  />
                  <InputForm
                    label={'Date of Birth'}
                    inputValue={dateOfBirth}
                    changeInput={(e)=>onChangeInput('dateOfBirth',e)}
                  />
                  <InputForm
                    label={'Place of Birth'}
                    inputValue={placeOfBirth}
                    changeInput={(e)=>onChangeInput('placeOfBirth',e)}
                  />  
                  <InputForm
                    label={'Country of Birth'}
                    inputValue={countryOfBirth}
                    changeInput={(e)=>onChangeInput('countryOfBirth',e)}
                  />
                  <InputForm
                    label={'Current Nationality'}
                    inputValue={nationality}
                    changeInput={(e)=>onChangeInput('nationality',e)}
                  />
                </div>
                <div className="info-right">
                  <InputForm
                    label={"Gender"}
                    inputValue={gender}
                    changeInput={(e)=>onChangeInput('gender',e)}
                  />
                  <InputForm
                    label={'Civil Status'}
                    inputValue={civilStatus}
                    changeInput={(e)=>onChangeInput('civilStatus',e)}
                  />
                  <InputForm
                    label={'Type of Travel Document'}
                    inputValue={travelType}
                    changeInput={(e)=>onChangeInput('travelType',e)}
                  />  
                  <InputForm
                    label={'Document ID Number'}
                    inputValue={docIdNumber}
                    changeInput={(e)=>onChangeInput('docIdNumber',e)}
                  />
                </div>
              </div>
              <div className="btn-box">
                <button className="btn-submit"
                  onClick={e => handleClick(e)}>
                  Submit
                </button>
              </div>
              
            </div>
            <div className="bottom-logo">
            <img alt="" width={26} src="/assets/img/visa/black_logo.png"/>
              <div className="logo-text">
                Powered by ShareRing
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Result;
