import { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import InputForm from "../../../components/common/InputForm";
import { add } from "lodash";

const Result = ({ name = "", email = "", phone = "", address="", dob=""}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [number, setNumber] = useState(data?.phone);      
  const [fullName, setFullName] = useState(data?.name);      
  const [addr, setAddress] = useState(data?.address);      
  const [email_address, setEmail] = useState(data?.email);      
  const [birth, setBirth] = useState(data?.dob);      

  console.log('Result data', data);
  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/bank/welcome", { });
    window.location.reload();
  };
  const onChangeInput = (type,e) => {
    switch (type){
      case 'name':
        setFullName(e.target.value)
        break
      case 'address':
        setAddress(e.target.value)
        break
      case 'number':
        setNumber(e.target.value)
        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'birth':
        setBirth(e.target.value)
        break
      default:
        break
    }
  };
  

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="bank-area" 
          style={{
            backgroundImage: "url('/assets/img/bank/background.png')",
            position:"absolute",
          }}
          > 
            <div className="content">
              <div className="top-logo">
                <img alt="" src="/assets/img/bank/logo.png"/>
                <div className="logo-text">
                  NEO bank
                </div>
                </div>
                <div className="info-section">
                  <div className="info-title"> Your information</div>
                  <InputForm
                    label={"Customer's Full Name"}
                    inputValue={fullName}
                    changeInput={(e)=>onChangeInput('name',e)}
                  />
                  <InputForm
                    label={'Mobile Number'}
                    inputValue={number}
                    changeInput={(e)=>onChangeInput('number',e)}
                  />

                  <div className="input-form">
                    <div className="input-label"> {'Residential Address'} </div>
                    <textarea className="input-text" id="multiliner" name="multiliner"
                    onChange={(e)=>onChangeInput('address',e)}>
                        {addr}
                    </textarea>
                  </div>  
                  
                  <InputForm
                    label={'Email Address'}
                    inputValue={email_address}
                    changeInput={(e)=>onChangeInput('email',e)}
                  />
                  <InputForm
                    label={'Date of Birth'}
                    inputValue={birth}
                    changeInput={(e)=>onChangeInput('birth',e)}
                  />
                  <button className="btn-submit"
                    onClick={e => handleClick(e)}>
                    Submit
                  </button>
                </div>
                <div className="bottom-logo">
                    <img alt="" width={26} src="/assets/img/hotel/logo.png"/>
                    <div className="logo-text">
                        Powered by ShareRing
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    </Fragment>
  )
}
export default Result;
