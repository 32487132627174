import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import SuccessModal from "./SuccessModal";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,  
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "65372fca7571370055204f5a" : "65533cbfa5d0ca006133f49d";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";


const Welcome = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");   
  const [status, setStatus] = useState(0);  
  const [qrcodeShortLink, setQrShortLink] = useState("");      
  const [deeplinkContent, SetDeeplinkContent] = useState("");      
  const [modalSuccessShow, setSuccessModalShow] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me"){
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");    
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);        
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {    
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };    
    APIServices.getQRRequestItems(params, (response) => {            
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );            
            let result = JSON.parse(data).qrRes;                          
            console.log('data', data);
            console.log('result', result);
            //sessionId = ""                                    
            if (result){       
              const res = result;                         
              const answer = res.split(":")[1];
              console.log('res', res);
              //navigate(process.env.PUBLIC_URL + "/vote/result", {state: {answer}});
              setStatus(1);
              setAnswer(answer);
              setSuccessModalShow(true);
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const onHide = () => {
    navigate('/vote/welcome');
    window.location.reload();
  }

  return (
    <Fragment>
      <Layout headerTop="visible">
        <div className="vote-area" 
          style={{
            backgroundImage: "url('/assets/img/visa/background.png')",
            position:"absolute",
          }}
          > 
            <div className="content">
                
                <div className="qrcode-section">
                  <div className="info-text">
                    <div className="title" >
                      CAST YOUR VOTE
                    </div>
                    <div className="description" >
                      Please scan the QR code 
                    </div>
                    <div className="description description-middle">
                      {'and enter 1, 2, or 3 '} <span className="description" style={{marginTop: 0, marginLeft:'10px', fontWeight:400}}>{'corresponding'}</span>
                    </div>
                    <div className="description">
                      to the options below to vote
                    </div>
                    <button className="list-btn list-btn-yes">
                      <div className="btn-namber">1</div>YES</button>
                    <button className="list-btn list-btn-no">
                      <div className="btn-namber">2</div>NO</button>
                    <button className="list-btn list-btn-abstain">
                      <div className="btn-namber">3</div>RETAIN</button>
                  </div>
                  {qrcodeShortLink?<div className="qr-code" onClick={goDeepLink}>
                    {<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}
                  </div>:
                  <div className="qr-code"/>}
                </div>
                <div className="bottom-logo">
                    <img alt="" width={26} src="/assets/img/visa/black_logo.png"/>
                    <div className="logo-text">
                        Powered by ShareRing
                    </div>
                </div>
            </div>
        </div>
      </Layout>
      <SuccessModal
        show={modalSuccessShow}
        onHide={() => onHide()}
        qrcode={qrCode}
        answer={answer}
      />
    </Fragment>
  )
}
export default Welcome;
