import { Fragment, useEffect, useState } from "react";
import './custom.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../layouts/Layout";
import APIServices from "../../../services/graphql/query";
import SuccessModal from "./SuccessModal";
import {
  DEEP_LINK_PREFIX,
  DEEP_LINK_SUFFIX,
  IDENTIFI_DEEP_LINK_PREFIX,
  IDENTIFI_DEEP_LINK_SUFFIX,
  SECURITY_WORD,
  SECURITY_WORD_ENCRYTED,
  ENV,
  HOST
} from "../../../services/graphql/config";
import QRCode from "react-qr-code";
import axios from "axios";
const cryptoJS = require("crypto-js");
const QUERY_ID = ENV ? "6413c739a4b2ae005d61d267" : "6553362ca5d0ca006133f25d";  //"64a42243ac5ded005dd2710f"  "64a233c04ecaf8005c5473d0"
let sessionId = "";


const CurrencyExchange = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState("");
  const [status, setStatus] = useState(0);
  const [qrcodeShortLink, setQrShortLink] = useState("");
  const [deeplinkContent, SetDeeplinkContent] = useState("");
  const [name, setName] = useState("");  
  const [dob, setDob] = useState("");  
  const [passportNum, setPassportNum] = useState("");  
  const [passportExpire, setPassportExpire] = useState("");  
  const [country, setCountry] = useState("");  
  const [modalSuccessShow, setSuccessModalShow] = useState(false);
  const goDeepLink = (mode) => {
    console.log('goDeepLink');
    let data = DEEP_LINK_PREFIX + deeplinkContent + DEEP_LINK_SUFFIX;

    if (mode == "identifi_me") {
      data = IDENTIFI_DEEP_LINK_PREFIX + deeplinkContent + IDENTIFI_DEEP_LINK_SUFFIX;
    }
    if (deeplinkContent)
      window.open(data, "_blank");
  }

  useEffect(() => {
    init();
  }, []);

  const decryptWithAES = (ciphertext, passphrase) => {
    const bytes = cryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(cryptoJS.enc.Utf8);
    return originalText;
  };

  const init = () => {
    console.log('init...');
    APIServices.generateQRSession((response) => {
      console.log('response', response);
      console.log('response.status', response.status);
      if (response.status === 1) {
        SetDeeplinkContent(encodeURIComponent(
          `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
            /&/g,
            "and"
          )
        ).replace(/%3A/g, ":"));
        let data =
          IDENTIFI_DEEP_LINK_PREFIX +
          encodeURIComponent(
            `query:${QUERY_ID}:${response.data.session_id}&key=${SECURITY_WORD_ENCRYTED}&qr_owner=`.replace(
              /&/g,
              "and"
            )
          ).replace(/%3A/g, ":") +
          IDENTIFI_DEEP_LINK_SUFFIX;
        console.log("data-----", data);
        sessionId = response.data.session_id;
        setQrCode(data);
        setQrShortLink(data);
        // APIServices.registerQueryShortLink({url: data}, (res) => {
        //   if (res.data){            
        //     const resData = JSON.parse(res.data);
        //     console.log('res.data.hortLinkId', resData.shortLinkId);
        //     const shortLinkId = resData.shortLinkId;
        //     setQrShortLink(`${HOST}${shortLinkId}`);
        //     console.log(`${HOST}${shortLinkId}`);
        //     setQrCode(data);
        //   }          
        // })
        onGetQRRequests();
      }
    });
  };

  const onGetQRRequests = () => {
    if (status != 0) return;
    if (sessionId == "") {
      setTimeout(onGetQRRequests, 1000);
      return;
    }
    const params = {
      queryId: QUERY_ID,
      sessionId: sessionId,
      userName: "",
      page: 1,
      pageSize: 1,
    };
    APIServices.getQRRequestItems(params, (response) => {
      if (response.status === 1) {
        if (response.data.items && response.data.items.length > 0) {
          if (response.data.items[0].completion === true) {
            let data = decryptWithAES(
              response.data.items[0].result,
              SECURITY_WORD
            );
            let result = JSON.parse(data).qrRes;
            console.log('data', data);
            console.log('result', result);
            //sessionId = ""                                    
            if (result) {
              const res = result.split("/n");
              console.log('res', res);

              //navigate(process.env.PUBLIC_URL + "/vote/result", {state: {answer}});
              const name = res.split("Name: ")[1].split("\n")[0];
              const date_of_birth = res.split("Date of Birthday: ")[1].split("\n")[0];
              const passport_no = res.split("Passport #: ")[1].split("\n")[0];
              const passport_expiry = res.split("Passport Expiry: ")[1].split("\n")[0];     
              const country = res.split("Country: ")[1].split("\n")[0];     

              setName(name);
              setDob(date_of_birth);
              setPassportNum(passport_no);
              setPassportExpire(passport_expiry);
              setCountry(country);                      
              setStatus(1);              
            } else {
              setStatus(-1);
              //navigate(process.env.PUBLIC_URL + "/building/failed", {deviceId: null});
            }
          } else {
            setTimeout(onGetQRRequests, 1000);
          }
        } else {
          setTimeout(onGetQRRequests, 1000);
        }
      }
    });
  };

  const onHide = () => {
    navigate('/currency-exchange');
    window.location.reload();
  }

  return (
    <div class="d-flex">
      <div class="left">
        <div class="logo">
          <img src="/demos/currencyExchange/assets/img/logo.png" />
        </div>
        <div class="qr-container">
          <h1 class="qr-title mb-4">Scan this QR code by ShareRing app to share information</h1>
          <div className="qrcode-section">
          <div style={{ width: "264px", height: "264px", margin: "0px", borderWidth: "0px", padding: "4px", curosr: "pointer", background: "white" }} onClick={goDeepLink}>{<QRCode size={256} value={qrcodeShortLink} viewBox={`0 0 256 256`} />}</div>
          </div>
        </div>
      </div>
      <div class="right position-relative">
        <div class="info-container">
          <div class="info-header">
            <h2 class="info-title">Your information</h2>
          </div>
          <div class="info-content">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Full name</label>
                  <input type="text" class="form-control" name="name" id="name" aria-describedby="helpId" placeholder="Your name" value={name} />
                    
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Date of Birth</label>
                  <input type="text" class="form-control" name="name" id="dob" aria-describedby="helpId" placeholder="Date of Birth" value={dob} />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Passport number</label>
                  <input type="text" class="form-control" name="name" id="passportNum" aria-describedby="helpId" placeholder="Your passport number" value={passportNum} />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Date of expiry</label>
                  <input type="text" class="form-control" name="name" id="passportExpire" aria-describedby="helpId" placeholder="Date of expiry" value={passportExpire} />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label for="name" class="form-label">Country</label>
                  <input type="text" class="form-control" name="name" id="country" aria-describedby="helpId" placeholder="Country" value={country} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-container">
          <div class="info-header">
            <h2 class="info-title">Transaction request</h2>
          </div>
          <div class="info-content">
            <div class="transaction-box">
              <label class="title">You transfer</label>
              <div class="box">
                <span class="amount">1,000</span>
                <div class="drop-down" data-src="cur-list-1">
                  <select name="options">
                    <option value="USD" data-image="./assets/img/currency/usd.png" selected>USD</option>
                    <option value="VND" data-image="./assets/img/currency/vnd.png">VND</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="reverse mt-4 mb-4">
            <a href="#"><img src="/demos/currencyExchange/assets/img/reverse.png" /></a>
          </div>
          <div class="info-content">
            <div class="transaction-box">
              <label class="title">You receive</label>
              <div class="box">
                <span class="amount">23,715,000</span>
                <div class="drop-down" data-src="cur-list-2">
                  <select name="options">
                    <option value="USD" data-image="./assets/img/currency/usd.png">USD</option>
                    <option value="VND" data-image="./assets/img/currency/vnd.png" selected>VND</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="make-transaction mt-4">
            <button class="btn btn-custom btn-request" onClick={() => setSuccessModalShow(true)} disabled={status == 0}>Make transaction request</button>
          </div>
        </div>
      </div>
      <SuccessModal
        show={modalSuccessShow}
        onHide={() => onHide()}
      />
    </div>
  );
};

export default CurrencyExchange;
